// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery-ui-dist/jquery-ui.js");

import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/css/all"

import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap"

import "@fortawesome/fontawesome-free/css/all"

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
window.Swal = Swal;

// import "lightbox2"
// import "lightbox2/dist/css/lightbox.min"

require("select2")
import "select2/dist/css/select2.min.css"
//import "select2-bootstrap-theme/dist/select2-bootstrap.min.css"

import "utils/confirm_dialog"
import "utils/spinner"
import "utils/popover"

import "photos"
import "import"
import "products"
import "comments"


require("styles/application.scss")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
